export const Urls = {
    BaseURL: 'cdn-base-url',
    
    // Page ids
    page_messages: '240',
    pa_dashboard: '239',
    vacancies_search: '241',
    homepage: '242',
    pa_profile: '244',
    pa_search: '243',
    employer_dashboard: '245',
    vacancy_profile: '246',
    about: '247',
    register: '251',
    pa_registration: '253',
    powys_dashboard: '262',
    powys_contact:'265',

    // homepage_hants: '242',
    page_hants_messages: '8',
    // pa_hants_dashboard: '6',
    pa_search_hants: '9',
    pa_profile_hants: '10',
    // employer_hants_dashboard: '7',
    vacancies_hants_search: '12',
    vacancy_hants_profile: '11',
    direct_provider_registration_hants: '13',
    program_id_hants: '6ece4325-a17c-41ef-a342-b10100fab0a0',
    register_hants: '14',
    dashboard_hants: '15',
    contact_form_hants_id: '10',
    info_hants: '18',

    // pa_hants_registration: '253',

    // Form ids
    form_equipmentHouse: '996',    

    // East Riding
    page_messages_er: '266',
    pa_dashboard_er: '276',
    vacancies_search_er: '267',
    homepage_er: '268',
    pa_profile_er: '269',
    pa_search_er: '270',
    employer_dashboard_er: '276',
    vacancy_profile_er: '271',
	about_er: '272',
	register_er: '274',
	pa_registration_er: '275',
	dashboard_er: '276',
    contact_form_er: '1074',
    program_id_er: '1ae56521-c651-408b-adf8-b13100e0d601',

    // Hampshire UAT attributes

    availability: [
        {
            name: "Flexible",
            guid: "9F0B84C5-E29A-4CFC-A761-B13700C8F574",
        },
        {
            name: "Monday to Friday (during evening)",
            guid: "09F4E708-80C3-48CF-9532-B13700C8F574",
        },
        {
            name: "Monday to Friday (early morning)",
            guid: "15CC3BA2-D5EA-4E9C-9FA7-B13700C8F574",
        },
        {
            name: "Monday to Friday (school hours)",
            guid: "6BC7E1BF-5AAE-4841-8344-B13700C8F574",
        },
        {
            name: "Sleep ins",
            guid: "A898208D-AED6-44BB-A245-B13700C8F574",
        },
        {
            name: "Waking nights",
            guid: "81FAE690-DCD2-4255-81EE-B13700C8F574",
        },
        {
            name: "Weekends",
            guid: "8E2A89BB-D471-4E60-BE85-B13700C8F574",
        }
    ],

    details: [
        {
          "name": "Valid UK Full driving licence",
          "guid": "2B339B26-C5F7-4F21-A9A4-B13700CC2442"
        },
        {
          "name": "Willing to support with parenting duties",
          "guid": "3FEA886C-F7FB-4F27-89AA-B13700CC2442"
        },
        {
          "name": "Willing to use own vehicle if suitable",
          "guid": "802810E6-CE78-4AEC-9485-B13700CC2442"
        },
        {
          "name": "Willing to work in a smoking environment",
          "guid": "1F4FCD0F-EC8D-423C-860F-B13700CC2442"
        },
        {
          "name": "Willing to work with pets",
          "guid": "6AB117EB-1FDA-4114-BF71-B13700CC2442"
        },
        {
          "name": "Would consider being a live-in carer",
          "guid": "A0EFE8F0-54AA-47CC-9C85-B13700CC2442"
        },
        {
          "name": "Would consider jobs for fewer hours",
          "guid": "0A9BAB9D-EE01-498C-9B2B-B13700CC2442"
        }
    ],

    locations: [
        {
          "name": "Aldershot",
          "guid": "72AADEA2-5DB3-4163-AE39-B13700D299B7"
        },
        {
          "name": "Alton",
          "guid": "59816B2D-11A2-4D4D-A08E-B13700D299B7"
        },
        {
          "name": "Alverstoke",
          "guid": "5210D837-FFBF-45E8-8D46-B13700D299B7"
        },
        {
          "name": "Andover",
          "guid": "78EA7699-5354-47DD-98BD-B13700D299B7"
        },
        {
          "name": "Ashurst",
          "guid": "EE8BAF27-8722-4FBE-AA46-B13700D299B7"
        },
        {
          "name": "Barton on Sea",
          "guid": "2B0D8F43-0B61-4FAE-8501-B13700D299B7"
        },
        {
          "name": "Basingstoke",
          "guid": "DCB75573-62A4-4D77-BAB9-B13700D299B7"
        },
        {
          "name": "Bishop Waltham",
          "guid": "053D1BFF-E038-42D4-9F83-B13700D299B7"
        },
        {
          "name": "Bordon",
          "guid": "34100938-582D-42BC-B218-B13700D299B7"
        },
        {
          "name": "Chandlers Ford",
          "guid": "E4EAF41A-1EC1-4BC6-8AB1-B13700D299B7"
        },
        {
          "name": "Eastleigh",
          "guid": "3F0562BB-2BCE-4128-BDD5-B13700D299B7"
        },
        {
          "name": "Emsworth",
          "guid": "15D339F8-2CD6-4C21-A61D-B13700D299B7"
        },
        {
          "name": "Fanborough",
          "guid": "B8A626E9-5FE6-43EA-86B2-B13700D299B7"
        },
        {
          "name": "Fareham",
          "guid": "D23A88C2-CBB4-4C98-B3D8-B13700D299B7"
        },
        {
          "name": "Farnham",
          "guid": "6D28EE58-6D18-4872-B113-B13700D299B7"
        },
        {
          "name": "Fleet",
          "guid": "CB1C19CE-C229-4B54-97DE-B13700D299B7"
        },
        {
          "name": "Fordingbridge",
          "guid": "FDCC61F4-FE9A-4EFA-B0D4-B13700D299B7"
        },
        {
          "name": "Gosport",
          "guid": "F8CC189A-9001-4B4A-814A-B13700D299B7"
        },
        {
          "name": "Havant",
          "guid": "53BED009-66E6-473F-AB02-B13700D299B7"
        },
        {
          "name": "Hayling Island",
          "guid": "4E3E74CF-16F7-4EFE-A6CE-B13700D299B7"
        },
        {
          "name": "Hedge End",
          "guid": "A27C2921-34F7-4C81-B15E-B13700D299B7"
        },
        {
          "name": "Horndean",
          "guid": "04746C84-83B5-4C01-A70E-B13700D299B7"
        },
        {
          "name": "Hythe",
          "guid": "F7B155F1-B790-4987-9750-B13700D299B7"
        },
        {
          "name": "Lee on Solent",
          "guid": "A9562A06-45B4-4D8F-9362-B13700D299B7"
        },
        {
          "name": "Liphook",
          "guid": "3219B15D-FE7D-49EF-87D0-B13700D299B7"
        },
        {
          "name": "Liss",
          "guid": "C3E03924-7D76-4284-A0D9-B13700D299B7"
        },
        {
          "name": "Locks Heath",
          "guid": "50342F3B-8634-42E0-A5AE-B13700D299B7"
        },
        {
          "name": "Locks Heath",
          "guid": "6F624674-644A-47D5-B7D9-B13700D299B7"
        },
        {
          "name": "Lymington",
          "guid": "1A3A53F5-44CE-47D1-8891-B13700D299B7"
        },
        {
          "name": "Milford on Sea",
          "guid": "821E0025-708B-429B-8051-B13700D299B7"
        },
        {
          "name": "Netley Abbey",
          "guid": "9E40EF9D-24C7-49BC-8EC7-B13700D299B7"
        },
        {
          "name": "New Milton",
          "guid": "9F0ACD98-7F16-4C1F-875E-B13700D299B7"
        },
        {
          "name": "Petersfield",
          "guid": "1EC21A53-6C3E-4A6B-833A-B13700D299B7"
        },
        {
          "name": "Portsmouth",
          "guid": "8DD3FA2D-73F4-4AF4-9562-B13700D299B7"
        },
        {
          "name": "Ringwood",
          "guid": "683ECCCE-E9E3-4D29-8086-B13700D299B7"
        },
        {
          "name": "Romsey",
          "guid": "82145D9B-696B-44CA-83DD-B13700D299B7"
        },
        {
          "name": "Southampton CENTRAL",
          "guid": "909B9C17-AAD1-4B9E-8703-B13700D299B7"
        },
        {
          "name": "Southampton EAST",
          "guid": "E757597F-5EDA-4311-B08B-B13700D299B7"
        },
        {
          "name": "Southampton WEST",
          "guid": "DA479643-58B7-40CF-9B1A-B13700D299B7"
        },
        {
          "name": "Stockbridge",
          "guid": "CD3A7B41-6FAE-48BA-995D-B13700D299B7"
        },
        {
          "name": "Totton",
          "guid": "712F3ED7-8AA9-48CA-8E28-B15300E61B0A"
        },
        {
          "name": "Waterlooville",
          "guid": "784F0676-4D89-4F7B-BD0D-B13700D299B7"
        },
        {
          "name": "West End",
          "guid": "EC8B8E41-CD14-4636-8235-B13700D299B7"
        },
        {
          "name": "Wickham",
          "guid": "F1265087-9692-4F19-AEAB-B13700D299B7"
        },
        {
          "name": "Winchester",
          "guid": "41DFC25D-5883-4541-85DD-B13700D299B7"
        }
    ],

    tasks: [
        {
          "name": "Dressing",
          "guid": "ACFBA889-2346-491F-9EDE-B13700D48F5B"
        },
        {
          "name": "Eating and drinking",
          "guid": "615B217A-2C44-49C1-BA38-B13700D48F5B"
        },
        {
          "name": "Exercise",
          "guid": "E6319FEF-0A87-4DD3-881B-B13700D48F5B"
        },
        {
          "name": "Housework/Domestic help",
          "guid": "6E954DFC-27B1-4E7E-8E58-B13700D48F5B"
        },
        {
          "name": "Meal preparation",
          "guid": "45345AC9-7F91-45B3-B7C2-B13700D48F5B"
        },
        {
          "name": "Medication management and administration",
          "guid": "B1F93319-184B-4F67-9A25-B13700D48F5B"
        },
        {
          "name": "Paperwork/Form filling",
          "guid": "7882E9A2-CB96-433E-83EB-B13700D48F5B"
        },
        {
          "name": "Personal care",
          "guid": "96CBC46E-C242-403D-9AD2-B13700D48F5B"
        },
        {
          "name": "Pet care",
          "guid": "DF4E1F59-52F7-4D3A-B869-B13700D48F5B"
        },
        {
          "name": "Support with family life",
          "guid": "4013B965-7C5E-4D61-9BDE-B13700D48F5B"
        },
        {
          "name": "Support with social activities",
          "guid": "A4986741-EFCA-41CF-8387-B13700D48F5B"
        },
        {
          "name": "Toilet needs",
          "guid": "A5FAA186-30AD-40C8-A101-B13700D48F5B"
        },
        {
          "name": "Transferring (Hoist/Manual)",
          "guid": "459E64A2-8FA9-49A7-859B-B13700D48F5B"
        },
        {
          "name": "Travel",
          "guid": "5CDA689C-817D-49DA-9BD1-B13700D48F5B"
        },
        {
          "name": "Other",
          "guid": "1FD2D9E6-C40C-4C95-B56B-B13700D48F5B"
        }
    ],

    ages:[
        {
          "name": "0-18",
          "guid": "405BB858-9198-4689-87BE-B13700C88706"
        },
        {
          "name": "18+",
          "guid": "BB1811BC-260B-44E8-B82D-B13700C8870B"
        }
    ],

    contract:[
        {
          "name": "Flexible cover",
          "guid": "0B505E18-80EA-45A5-B436-B13700C95EC1"
        },
        {
          "name": "Permanent",
          "guid": "D94D33FC-7B6E-4B02-AABF-B13700C95EC1"
        },
        {
          "name": "Temporary",
          "guid": "97426D00-6E52-43A2-8829-B13700C95EC1"
        }
    ],

    day:[
        {
          "name": "Monday",
          "guid": "923B6D0A-5A19-491D-9025-B13700CBB499"
        },
        {
          "name": "Tuesday",
          "guid": "4D1524EB-D0EF-434C-A4D7-B13700CBB499"
        },
        {
          "name": "Wednesday",
          "guid": "6C996E25-3C88-4B2D-93C2-B13700CBB499"
        },
        {
          "name": "Thursday",
          "guid": "3C36026A-A780-4D5F-9B1D-B13700CBB499"
        },
        {
          "name": "Friday",
          "guid": "9CFDAA4B-EFE5-46EB-83D6-B13700CBB499"
        },
        {
          "name": "Saturday",
          "guid": "0A4DFDB2-D2D1-4A03-83B7-B13700CBB499"
        },
        {
          "name": "Sunday",
          "guid": "802B10AE-C67B-4EB9-8D71-B13700CBB499"
        },
        {
            "name": "Flexible",
            "guid": "E12975A9-19C9-4D49-827F-B13700CBB499"
        }
    ],

    reference:[
        {
          "name": "I can supply a personal reference",
          "guid": "08B6CB99-0304-493A-8391-B13700CC5153"
        },
        {
          "name": "I can supply a professional reference",
          "guid": "447C5E6B-2137-4203-9E13-B13700CC5153"
        }
    ],

    right:[
        {
          "name": "No",
          "guid": "2AC6E4A3-B90F-48FB-96B0-B13700CCC5AC"
        },
        {
          "name": "Yes",
          "guid": "99F80665-3A6D-41F5-823D-B13700CCC5AC"
        }
    ],

    drivingLicence:[
        {
          "name": "No",
          "guid": "461E2D18-CADF-4337-AF96-B13700CCE750"
        },
        {
          "name": "Yes",
          "guid": "3080F099-BD19-4C11-B340-B13700CCE750"
        }
    ],

    experience:[
        {
          "name": "Alzheimer's Disease/Dementia",
          "guid": "8EFB27B6-54C6-4C42-9DBC-B13700D03A0D"
        },
        {
          "name": "Autism",
          "guid": "39151370-EC8C-487C-A982-B13700D03A0D"
        },
        {
          "name": "Brain Injury",
          "guid": "B0C0E8CA-1548-49CF-A066-B13700D03A0D"
        },
        {
          "name": "Cerebral Palsy",
          "guid": "98F6EBF9-5DF8-4A1F-80DF-B13700D03A0D"
        },
        {
          "name": "Challenging Behaviour",
          "guid": "22EF7F7D-4A01-4F9F-85D1-B13700D03A0D"
        },
        {
          "name": "Communication Problems",
          "guid": "384FACF2-3C67-4076-96BA-B13700D03A0D"
        },
        {
          "name": "Down Syndrome",
          "guid": "38EAFD3E-8937-4D1F-A569-B13700D03A0D"
        },
        {
          "name": "Epilepsy",
          "guid": "A6404A64-2390-48F1-93C4-B13700D03A0D"
        },
        {
          "name": "Learning Disability",
          "guid": "ECB32E51-7480-4800-B4FF-B13700D03A0D"
        },
        {
          "name": "Mental Health",
          "guid": "4689A1F9-D645-401C-9AB6-B13700D03A0D"
        },
        {
          "name": "Mobility Impairments",
          "guid": "2B72EA8C-D997-4985-BFED-B13700D03A0D"
        },
        {
          "name": "Parkinson's Disease",
          "guid": "FA531E56-5196-40FE-8E01-B13700D03A0D"
        },
        {
          "name": "Spinal Injury",
          "guid": "13F44C63-629D-407C-8D53-B13700D03A0D"
        },
        {
          "name": "Stroke",
          "guid": "770CA58F-13E6-43F6-9C00-B13700D03A0D"
        },
        {
          "name": "Visual Impairment",
          "guid": "B64802F9-3821-400D-9437-B13700D03A0D"
        },
        {
          "name": "Other",
          "guid": "0357AFCB-ACEB-4AF8-B433-B13700D03A0D"
        }
    ],

    hours:[
        {
          "name": "Up to 16 hours",
          "guid": "0A793331-93A8-4BF4-89CA-B13700D08A22"
        },
        {
          "name": "16-24 hours",
          "guid": "0175059E-3C16-44E2-B32C-B13700D08A22"
        },
        {
          "name": "25-37 hours",
          "guid": "A65925FA-2D08-4B6E-9798-B13700D08A22"
        },
        {
          "name": "37-40 hours",
          "guid": "002F4A47-FC09-4CC9-997D-B13700D08A22"
        },
        {
          "name": "Other",
          "guid": "245B8B97-8938-4F88-9717-B13700D08A22"
        }
    ],

    languages:[
        {
          "name": "Arabic",
          "guid": "37D8460F-471E-43D8-9E9B-B13700D1074D"
        },
        {
          "name": "British Sign Language",
          "guid": "BDFE2C69-7830-4A18-AAFB-B13700D1074D"
        },
        {
          "name": "Chinese",
          "guid": "465DFB8E-F75C-4F4E-A209-B13700D1074D"
        },
        {
          "name": "English",
          "guid": "A67C2A88-1F55-4023-B03C-B13700D1074D"
        },
        {
          "name": "Hindi",
          "guid": "3D4C7F8D-72B4-4EF9-A08C-B13700D1074D"
        },
        {
          "name": "Makaton",
          "guid": "9CFD08B8-6812-4BBF-A63B-B13700D1074D"
        },
        {
          "name": "Nepalese",
          "guid": "7A633067-B3E3-4220-922C-B13700D1074D"
        },
        {
          "name": "Polish",
          "guid": "09D60FF8-3586-4808-87A2-B13700D1074D"
        },
        {
          "name": "Portuguese",
          "guid": "E7F7C1F0-744A-4EFA-A768-B13700D1074D"
        },
        {
          "name": "Punjabi",
          "guid": "9FD8EF0C-EC8C-4FF2-AC62-B13700D1074D"
        },
        {
          "name": "Spanish",
          "guid": "EBFF2B04-84DA-4B85-B036-B13700D1074D"
        },
        {
          "name": "Tamil",
          "guid": "C728C4B3-0945-45DC-A172-B13700D1074D"
        },
        {
          "name": "Urdu",
          "guid": "B1236ED6-9428-4DCD-84B5-B13700D1074D"
        },
        {
          "name": "Other",
          "guid": "DA5F867A-AA70-4755-BEF0-B13700D1074D"
        }
    ],

    gender:[
        {
          "name": "Female",
          "guid": "8A58F81A-43D0-45D5-9B91-B13700D2F05E"
        },
        {
          "name": "Male",
          "guid": "42C25596-DDA4-4763-A38C-B13700D2F05E"
        },
        {
          "name": "Non-Binary",
          "guid": "A90C5680-4990-4EB4-B753-B13700D2F05E"
        },
        {
          "name": "Prefer not to say",
          "guid": "9216E971-377A-44A3-932E-B13700D2F05E"
        }
    ],

    hobbies:[
        {
          "name": "Art and culture",
          "guid": "8A132974-3E58-4A3E-AB4A-B13700D3851C"
        },
        {
          "name": "Books and reading",
          "guid": "12C018EA-0A9A-43E0-944E-B13700D3851C"
        },
        {
          "name": "Cinema and movies",
          "guid": "517F4767-84A6-407F-BF14-B13700D3851C"
        },
        {
          "name": "Computers",
          "guid": "1FD924B0-0AD7-43DE-BC78-B13700D3851C"
        },
        {
          "name": "Cooking and baking",
          "guid": "E26F4527-15D1-4993-9882-B13700D3851C"
        },
        {
          "name": "Gardening",
          "guid": "79757C50-5FAF-4510-A5D1-B13700D3851C"
        },
        {
          "name": "Health and fitness",
          "guid": "36EB9FD9-C0CE-4A84-AB38-B13700D3851C"
        },
        {
          "name": "Hobbies and crafts",
          "guid": "9EBF94A1-A823-436B-9046-B13700D3851C"
        },
        {
          "name": "Music and concerts",
          "guid": "D6D5666E-A9F6-4176-99CF-B13700D3851C"
        },
        {
          "name": "Outdoor activities",
          "guid": "92136A4A-8799-4DE8-A34B-B13700D3851C"
        },
        {
          "name": "Politics",
          "guid": "3E01E72C-611B-434A-906C-B13700D3851C"
        },
        {
          "name": "Religion",
          "guid": "E3CDCB6E-281D-41BD-A380-B13700D3851C"
        },
        {
          "name": "Socialising",
          "guid": "8E698512-9F0D-45E3-AF8F-B13700D3851C"
        },
        {
          "name": "Sports",
          "guid": "649FA989-53C1-4838-AA6A-B13700D3851C"
        },
        {
          "name": "Travel",
          "guid": "466250C3-51BC-43D4-AF6C-B13700D3851C"
        },
        {
          "name": "Video games",
          "guid": "CE2305D7-AC05-47B9-B913-B13700D3851C"
        },
        {
          "name": "Wildlife and nature",
          "guid": "2142E122-881F-4E6A-BD97-B13700D3851C"
        },
        {
          "name": "Other",
          "guid": "AC10E025-1C04-4ADF-BB2B-B13700D3851C"
        }
    ],

    qualities:[
        {
          "name": "Cheerful",
          "guid": "CE3B0348-BEDD-44A6-973A-B13700D41139"
        },
        {
          "name": "Competent driver",
          "guid": "92007A3A-E781-492C-B556-B13700D41139"
        },
        {
          "name": "Conscientious",
          "guid": "206AE598-F5E1-4DC4-9E0A-B13700D41139"
        },
        {
          "name": "Empathetic",
          "guid": "996A052C-FDCD-4AC4-AB30-B13700D41139"
        },
        {
          "name": "Fast learner",
          "guid": "31B49D2A-A366-49AD-838D-B13700D41139"
        },
        {
          "name": "Flexible",
          "guid": "00F52491-3115-46B9-AB14-B13700D41139"
        },
        {
          "name": "Good cook",
          "guid": "DE244F65-B321-418F-9BB3-B13700D41139"
        },
        {
          "name": "Good with animals",
          "guid": "39A3FFB4-5E00-4981-A3B8-B13700D41139"
        },
        {
          "name": "Good with children",
          "guid": "169E84F4-CBE1-46B9-B479-B13700D41139"
        },
        {
          "name": "Good with time keeping",
          "guid": "4BB51987-801D-44F8-9CC2-B13700D41139"
        },
        {
          "name": "Kind",
          "guid": "2B3A673B-E493-45AC-B4FA-B13700D41139"
        },
        {
          "name": "Outgoing",
          "guid": "00D4EBE8-CEA2-482A-A636-B13700D41139"
        },
        {
          "name": "Patient",
          "guid": "9B167338-98AF-41EA-A10F-B13700D41139"
        },
        {
          "name": "Reliable",
          "guid": "C4769250-267D-4394-8F68-B13700D41139"
        },
        {
          "name": "Resourceful",
          "guid": "5379A13E-E524-480C-8A4E-B13700D41139"
        },
        {
          "name": "Sense of humour",
          "guid": "F50A208A-066E-4B90-811D-B13700D41139"
        },
        {
          "name": "Other",
          "guid": "9DDBFBC5-34E7-4A6B-B791-B13700D41139"
        }
    ],

    team:[
        {
          "name": "No",
          "guid": "AB2483EA-77E7-400A-A11C-B13700D4AC19"
        },
        {
          "name": "Yes",
          "guid": "74B021DC-89BE-4C6D-B57C-B13700D4AC19"
        }
    ]    
}